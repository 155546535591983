
/*****************************************
* ECCO - PLACEHOLDERS
*****************************************/

@include ecco-import-once("ecco-placeholders") {

	%reset {
		margin: 0;
		padding: 0;
	}


	%reset-list {
		@extend %reset;
		list-style-type: none;
	}


	%reset-input {
		border-radius: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		background: transparent none;
		box-shadow: none;

		&:focus {
			box-shadow: none;
		}
	}


	%fill {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	%fixed-fill {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}


	%center {
		margin-left: auto;
		margin-right: auto;
	}


	%truncate-text {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}


	%vh {
		position: absolute;
		left: -9999em;
		top: 0;
		width: 0;
		height: 0;
		line-height: 0;
		overflow: hidden;
		outline: 0;
	}


	%border-box {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}


	%sf {
		&::after {
			content: "";
			display: table;
			font-size: 0;
		   clear: both;
		}
	}
}
