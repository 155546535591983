﻿/*vsechny kalendare na webu*/

#kalakci {
	min-width: 7em;
	width: auto;
}

// .calendar {
// 	margin-top: -216px;
// 	width: auto;
// 	text-align: center;
// 	z-index: 50;
	
// 	td {
// 		text-align: center;
// 	}
	
// 	select {
// 		width: 6em;
// 		margin: 0 .3em;

// 		& + select {
// 			margin-left: 0;
// 		}
// 	}
	
// 	input {
// 		width: 5.6em;
// 		margin: 0 0 3px 1px;
// 		cursor: pointer;
// 	}
// }

div.mainheading {
	margin: 6px 8px 0 10px;
	
	input {
		width: auto;
		margin: 0;
	}
}

.calcells,
.caldayheading {
	width: 95%;
	margin: 0;
}

.calendar .calcells td {
	padding: 7px 0;

	&.wkhead {
		background-color: transparent;
	}
}

.caldayheading {
	margin-bottom: -5px;
}

.calcells td,
.caldayheading td {
	font-weight: normal;
	width: 10%;
	text-align: center;
	padding: 2px;
}

.calcells thead th {
	text-align: center;
}

.calcells {
	td.hover,
	a {
		cursor: pointer;
	}
	
	td.curdate {
		padding: 0 !important;
	}
}

#kalakci {
	border:0;

	td > a {
		display: block;
		// margin: -8px 0;
		// padding: 8px 0;
		font-weight: bold;
	}
	
	.calcells {
		@extend %reset;
		width: 100%;

		td {
			// padding: 8px 0;
			width: (100% / 7);
		}

		tr th.cist:first-child {
			display: none;
		}
	}

	// td.curdate a {
	// 	margin: -2px 0;
	// 	padding: 6px 0;
	// }
	
	@include link-over {
		text-decoration: underline;
	}
}

.akcekal,
#kalendar {
	position: relative;
	z-index: 1;
	overflow: visible!important;
}


/*******************************
* VYSKAKOVACÍ SEZNAM AKCÍ
*******************************/

.floatingBubbleElm {
	border: 1px solid #FFFFFF;
	position: absolute;
	display: none;
	left: auto;
	z-index: 50;
	text-align: left;
	height: auto !important;
	margin-top: 0;
	width: 13em;
	padding: 4px; // po dohode s odbornikem na BB pro ctecky znepristupneno (dostatecna nahrada)

	div.vypisakci {
		overflow: auto;
	}
	
	ul {
		margin: 0 !important;
		padding: 5px 0 8px 0 !important;
		width: auto;
		height: auto !important;
		background-color: #ffffff !important;
	}
	
	li {
		padding: 0 4%;
		margin: 0 !important;
		display: block;
		list-style-type: none;
		border-top: 1px #cecece solid;
		color: #000000 !important;
		width: auto;
	}
	
	li:first-child,
	li.firstDayAction {
		border: 0;
		padding-top: 5px;
	}
}

#kalakci .floatingBubbleElm a {
	font-weight: normal !important;
	display: block;
	padding: 8px 0 !important;
	background: none !important;
	position: static !important;
	text-decoration: none !important;
	color: #5F5F5F !important;
}

#celek #kalakci .floatingBubbleElm p {
  background-color: transparent !important;
	text-align: center;
	font-weight: bold;
	background: transparent none !important;
	margin: 0;
	padding: 3px 5px;
}

.floatingBubbleIsVisible {
	left: auto;
	display: block;
}

.floatingBubbleIsInvisible {
	display: none;
} // po dohode s odbornikem na BB pro ctecky znepristupneno (dostatecna nahrada)
