
@media screen {

/*barvy*/
#voliviewer-counter,#voliviewer-counter *,#voliviewer_bottom_toolbar,#voliviewer_bottom_toolbar *,#infoPanel,#infoPanel *{color:#9E9E9E!important;}
#infoPanel,#navigationPanel,#imgBgPanel{background-color:#000000;}

#navigationPanel,#voliviewer_bottom_toolbar{font-size:92%;}
#initImage_Info{font-size:100%;display:block;position:absolute;width:100%;top:45px!important;left:0!important;text-align:center;}
#navigationPanel,#navigationPanel *,#voliviewer_bottom_toolbar,#voliviewer_bottom_toolbar *{font-weight:normal;}

#imgBgPanel{z-index:100;position:absolute;left:0;}
#imgPanel{background-color:transparent;position:absolute;z-index:110;}
#navigationPanel{position:absolute;z-index:100;cursor:default;}
#infoPanel{position:absolute;height:auto;z-index:110;cursor:default;text-align:left;}
#overImgLayout{position:absolute;z-index:150;background-color:#000000;}
.voliviewer-arrowLeft,.voliviewer-arrowRight{position:absolute;width:50px;height:50px;background-color:#000000;display:block;}

#voliviewer_top_toolbar{position:absolute;width:inherit;float:right;margin-top:0px;left:0;width:100%;z-index:30;font-size:71%;}
#voliviewer_top_toolbar a{font-weight:normal;color:#D0D0D0;text-decoration:none;}
#voliviewer_top_center_toolbar{padding:10px 0px 0px 0px;}
#voliviewer-counter{position:absolute;left:0;margin:2px 0px 0px 10px;height:20px;}

#voliviewer_buttons_toolbar{position:relative;float:right;}
#voliviewer_buttons_toolbar a{position:relative;}

.voliviewer-buttons {
	width:125px;
	margin:0 auto;

	@include link-all {
		text-decoration:none!important;
	}
}

.voliviewer-buttons img{float:left;margin-right:7px;}

/*_KN_20111201_k STYLY PRO PEKNY SLIDESHOW*/
#buttonEnd img{margin-right:0;}
.voliviewer-slideshowInfo{float:left;font-weight:normal;padding-right:0;padding-top:1px;}
.voliviewer-floatInfoPanel{background-color:#FFFFFF;color:#000000;padding:5px;}

#otherLinksArea{float:right;padding:0px;float:right;position:absolute;margin-right:10px;right:0px;}
#voliviewer_bottom_toolbar{height:auto;min-height:22px;margin:5px 0px 0px 0px;padding:2px 10px 8px 10px;z-index:20;}
#voliviewer_bottom_toolbar a{font-weight:normal;text-decoration:underline;}

.voliviewer-info{padding:10px 10px 0px 10px;font-size:145%;}
#voliviewer-buttons-light{float:right;margin:0px 15px 10px 0px;}

#voliviewer_description{font-size:71%;padding:2px 10px 0 10px;z-index:20;color:red;}

}