
/***************************************
*	POČASÍ - OPENWEATHERMAP - MAX 5 DNÍ
***************************************/

.owm {
	text-align: center;
	list-style-type: none;
	margin: 0;
	padding: 0;
	font-size: rem(16px);

	span[class*="owm"] {
		display: block;
	}

	.owmIcon {
		padding: .5em 0;
	}

	li {
		display: inline-block;
		padding: 0;
		margin: 0 1em 1em 1em;
	}

	&.owm-1,
	&.owm-2,
	&.owm-3 {
		li {
			.owmDateDayName {
				display: none;
			}
		}
	}

	&.owm-4,
	&.owm-5 {
		li {
			.owmDateDay {
				display: none;
			}
		}
	}

	@for $i from 1 through 5 {
		&.owm-#{$i} li {
			@include limit-items($i);
		}
	}

	.owmTempMax {
		font-size: rem(19px);
	}

	img{ 
		width: 46px;
		height: auto;
		//max-width: 46px;
	}
}

.vzor-e-2016 {
	.wrapper-novinky .dok li > strong {
		padding-bottom: 12px;
	}

	.horizontal .dok ul li:last-child .dok-telo {
		padding-bottom: 40px;
	}

	#kontakt address,
	#anketa .dotaz {
		line-height: 1.55;
	}

	.wrapper.wrapper-uvod {
		margin-bottom: 0;

		&::after {
			content: "";
			padding-bottom: 75px;
			height: 0;
			display: block;
		}
	}

	#anketa ul li {
		margin-bottom: 20px;
	}

	.titulodkazy li {
		margin: 8px 0;
	}

	#obrazek #blender {
		padding-bottom: 30%;
		
		@include bp("> 1280px") {
			padding-bottom: 40%;
		}
		
		.prolinani-e-obrazek {
			width: 30%;
			padding-bottom: 27.88%;

			@include bp("> 1280px") {
				width: 40%;
				padding-bottom: 37.88%;
			}
		}
	}

	.owm {
		li {
			text-align: center;

			&:first-child {
				margin-left: 0;
			}
		}
	}
}